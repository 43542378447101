
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, reactive, ref} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment"
import mAxiosApi from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

export default defineComponent({
  name: "Commissions",
  components: {
    Datatable,
    SearchAndFilter,
  },

  setup() {
    setCurrentPageBreadcrumbs("Commissions", ["Pages", "Profile"]);

    const state = reactive({
      loading: true,
      byMois: '',
      sousTotaux: {
        payes: 0,
        attente: 0,
        periode: 0,
        total: 0,
      },
      loadingDatatable: false,
      updateList: 0,
      myList: [] as any,
      allRest: [] as any,
      initialList: [] as any,
      displayTotaux: false,
    });

    const tableHeader = ref([
      {
        name: "Facture<br>Client",
        key: "tfa_date_creation",
        sortable: true,
      },
      {
        name: "Projet<br/>Facture",
        key: "pro_code",
        sortable: false,
      },
      {
        name: "% com",
        key: "tfa_pourc_com",
        sortable: true,
      },
      {
        name: "Marge<br/>Commissions",
        key: "total_com",
        sortable: true,
      },
      {
        name: "Paiement",
        key: "tfa_x_paye",
        sortable: true,
      },
      {
        name: "",
        key: "action",
        sortable: false,
      },
    ]);

    const tableFilters = ref([] as any)
    // Gerer mettre dans default le mois de la dernière ligne 

    const formatDate = (d) => {
      return moment.default(d).format("DD/MM/YYYY");
    };

    const refreshDatas = async (seq, allSeqs = []) => {
      if (seq == -1) {
        const response = await mAxiosApi.prototype.getAxios("/getCommissions");
        allSeqs.forEach(seq => {
          const initialIndex = state.initialList.findIndex(el => el.tfa_seq == seq);
          const myListIndex = state.myList.findIndex(el => el.tfa_seq == seq);
          state.initialList[initialIndex] = response.results[response.results.findIndex(el => el.tfa_seq == seq)];
          state.myList[myListIndex] = response.results[response.results.findIndex(el => el.tfa_seq == seq)];
        });
      }else{
        const response = await mAxiosApi.prototype.getAxios("/getCommissions/" + seq);
        const initialIndex = state.initialList.findIndex(el => (el as any).tfa_seq == seq);
        const myListIndex = state.myList.findIndex(el => (el as any).tfa_seq == seq);
        state.initialList[initialIndex] = response.results[0];
        state.myList[myListIndex] = response.results[0];
      }
      calcTotaux();
      state.updateList = state.updateList + 1;
    }

    const payAll = async() => {
      let moreThanZero = false;
      const allSeqs = [] as any;
      state.myList.forEach(async (el) =>{
        if (el.tfa_x_paye != 1 && state.allRest.length == 0) {
          moreThanZero = true;
          allSeqs.push(el.tfa_seq)
        }
      });
      if (moreThanZero) {
        const datas = {ids: allSeqs}
        console.log(datas)
        await mAxiosApi.post("/payCommission", datas)
        refreshDatas(-1, allSeqs);
      }

      const plural = allSeqs.length != 1 ? 's ' : ' ';
      ElMessage({
        type: 'success',
        message: allSeqs.length + " commission" + plural + "passée" + plural + "en 'Payées'",
      })
    }

    const payTheCom = (tfa_x_paye, seq) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        ElMessageBox.confirm(
          "Voulez vous passer cette commission en 'Payées' ?",
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
          }
        )
        .then(async() => {
          const datas = {ids: [seq]}
          await mAxiosApi.post("/payCommission", datas);
          refreshDatas(seq);
          ElMessage({
            type: 'success',
            message: "Commission passée en 'Payées'",
          })
        })
      }
    }

    const editCom = (tfa_x_paye, seq) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        ElMessageBox.prompt('Entrez le nouveau pourcentage', 'Modification du pourcentage', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          inputPattern:
            /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$/,
          inputErrorMessage: 'Pourcentage incorrect',
        })
        .then(async({ value }) => {
          await mAxiosApi.post("/editPercCommission/" + seq + "/" + value);
          refreshDatas(seq);
          ElMessage({
            type: 'success',
            message: `Nouveau pourcentage: ${value}%`,
          })
        })
      }
    }

    const changeTitle = (data) => {
      const initialTitle = document.title;
      document.title = 'display_project=' + data;
      setTimeout(() => {
        document.title = initialTitle
      }, 3000);
    }

    const calcTotaux = () => {
      state.sousTotaux = {
        payes: 0,
        attente: 0,
        periode: 0,
        total: 0,
      }
      state.myList.forEach(element => {
        if (element.tfa_x_paye == 1) {
          state.sousTotaux.payes += parseFloat(element.total_com); 
        }else{
          state.sousTotaux.attente += parseFloat(element.total_com);
        }
        state.sousTotaux.periode += parseFloat(element.total_marge);
        state.sousTotaux.total += parseFloat(element.total_com); 
      });
    }

    const comClasses = ((tfa_x_paye) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        return 'btn-light-primary btn-sm cursor-pointerIMP'
      }
    })

    const payClasses = (tfa_x_paye) => {
      if (tfa_x_paye == 1) {
        return 'badge-light-success'
      }else{
        return state.allRest.length == 0 ? 'badge-light-warning cursor-pointer' : 'badge-light-warning'
      }
    }

    const getFormattedTotal = (value) => {
      const n = (Math.ceil(value*100)/100).toFixed(2);
      return commify(n) + ' €';
    }

    function commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");
    }
    
    onMounted(async () => {
      const response = await mAxiosApi.prototype.getAxios("/getCommissions");
      state.initialList = response.results;
      state.myList = state.initialList;
      if (state.myList.length>0) {
        tableFilters.value.push({ name: "Mois", key: "keymonth", label: "displaymonth", default: [state.initialList[0].keymonth] })
      }
      // state.defaultFilter = "10 / 2023"
      // console.log(tableFilters.value.key)
      calcTotaux();
      
      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'EDIT_COM'
      );
      state.loading = false
    });

    return{
      state,
      tableHeader,
      tableFilters,
      formatDate,
      changeTitle,
      payTheCom,
      editCom,
      payAll,
      calcTotaux,
      comClasses,
      payClasses,
      getFormattedTotal
    }
  }
});
