<template>
<transition name="fade">
  <div v-if="!state.loading">
    <div class="d-flex flex-wrap flex-stack my-5">
      <h2 class="fw-bold my-2 d-none d-md-block text-uppercase text-white custom-title">
        Liste des commissions d'affaire
      </h2>
      <div class="d-none d-md-flex align-items-center my-2 w-100 w-md-auto" v-if="state.allRest.length == 0">
        <el-popconfirm title="Considérer TOUTES les lignes comme payées ?" @confirm="payAll()" :confirm-button-text="$t('Oui')" :cancel-button-text="$t('Non')"  icon-color="danger">
          <template #reference>
            <button class="btn btn-primary mt-3 mt-md-0 w-100">Considérer toutes les lignes comme payées</button>
          </template>
        </el-popconfirm>
      </div>
      <div class="d-flex d-md-none align-items-center my-2 w-100 w-md-auto">
        <button class="btn btn-primary mt-3 mt-md-0 w-100" @click="state.displayTotaux = !state.displayTotaux">{{state.displayTotaux ? 'Masquer' : 'Afficher'}} les filtres et sous-totaux<i class="bi ps-6" :class="{'bi-chevron-down': !state.displayTotaux, 'bi-chevron-up': state.displayTotaux}"></i></button>
      </div>
    </div>

    <Transition>
      <div class="card d-md-none mb-5 open-totaux" v-show="state.displayTotaux">
        <div class="card-body">
          <el-tabs class="tabsPresta mb-3">
            <el-tab-pane disabled :label="'Filtres'" ></el-tab-pane>
          </el-tabs>
          <SearchAndFilter
            :listInitial="state.initialList"
            :filters="tableFilters"
            :key="tableFilters"
            classSearch="h-40px"
            :widthDecalage="-440"
            classSelect="w-100"
            @returnSearch="state.myList = $event; calcTotaux(); state.updateList = state.updateList + 1"
            @returnLoadingDatatable="state.loadingDatatable = $event"
          >
          </SearchAndFilter> 
          <el-tabs class="tabsPresta mb-3">
            <el-tab-pane disabled :label="'Sous-totaux'" ></el-tab-pane>
          </el-tabs>

          <div class="row mb-3">
            <label class="col-lg-12 col-form-label fw-bolder fs-7">Marge sur la période :</label>
            <div class="col-lg-12 col-12 fv-row ps-15">
              <input disabled type="text" class="form-control ps-4 text-end w-80" :value="getFormattedTotal(state.sousTotaux.periode)" />
            </div>
          </div>  

          <div class="row mb-3">
            <label class="col-lg-12 col-form-label fw-bolder fs-7">Commissions payées :</label>
            <div class="col-lg-12 col-12 fv-row ps-15">
              <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.payes)" />
            </div>
          </div>  

          <div class="row mb-3">
            <label class="col-lg-12 col-form-label fw-bolder fs-7">Commissions en attente :</label>
            <div class="col-lg-12 col-12 fv-row ps-15">
              <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.attente)" />
            </div>
          </div>  

          <div class="row mb-3">
            <label class="col-lg-12 col-form-label fw-bolder fs-7">Soit un total :</label>
            <div class="col-lg-12 col-12 fv-row ps-15">
              <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.total)" />
            </div>
          </div>  
        </div>
      </div>
    </Transition>


    <el-container>
      <el-main class="ms-0 p-0 p-md-6 overflow-hidden">

        <div class="card-header d-none d-md-flex border-0 p-0 gap-4">
              <SearchAndFilter
                :listInitial="state.initialList"
                :filters="tableFilters"
                :key="tableFilters"
                widthDecalage = "-140"
                classSearch="h-40px"
                @returnSearch="state.myList = $event; calcTotaux(); state.updateList = state.updateList + 1"
                @returnLoadingDatatable="state.loadingDatatable = $event"
              >
              </SearchAndFilter> 
            </div>     
            
            <div class="card-body py-0 px-0 px-md-1">

              <Datatable
                v-if="state.myList && state.myList.length > 0"
                :table-data="state.myList"
                :table-header="tableHeader"
                :enable-items-per-page-dropdown="true"
                :empty-table-text="'Aucune offre à afficher.'"
                class="d-none d-md-block"
                :key="state.updateList"
              >
                <template v-slot:cell-tfa_date_creation="{ row: data }">
                  <div class="text-left d-flex flex-column min-w-200px">
                    <span class="fw-bolder text-dark text-left fs-7">{{ formatDate(data.tfa_date_creation) }}</span>
                    <span class="text-muted fw-bold fs-7">{{ data.soc_nom_societe }}</span>
                  </div>
                </template>
                <template v-slot:cell-pro_code="{ row: data }">
                  <div>
                    <span class="text-dark fw-bolder mb-1 fs-6">{{ data.pro_code }}</span>
                    <span class="text-muted fw-bold d-block fs-7">{{ data.tfa_code }}</span>
                  </div>
                </template>
                <template v-slot:cell-tfa_pourc_com="{ row: data }">
                    <div class="text-end fs-8 btn fw-bolder cursor-text" style="cursor: text;" :class="comClasses(data.tfa_x_paye)" @click="editCom(data.tfa_x_paye, data.tfa_seq)">{{ data.tfa_pourc_com }}{{data.tfa_pourc_com ? '%' : '-'}}</div>
                </template>
                <template v-slot:cell-total_com="{ row: data }">
                  <div class="text-end w-100px">
                    <span class="text-muted fw-old d-block fs-7">{{ getFormattedTotal(data.total_marge) }}</span>
                    <span class="fw-bolder mb-1 fs-6 text-primary">{{ getFormattedTotal(data.total_com) }}</span>
                  </div>
                </template>
                <template v-slot:cell-tfa_x_paye="{ row: data }">
                  <div class="badge fs-7" :class="payClasses(data.tfa_x_paye)" @click="payTheCom(data.tfa_x_paye, data.tfa_seq)">
                    <i v-if="data.tfa_x_paye != 1 && state.allRest.length == 0" class="bi bi-square text-warning me-3"></i>
                    <i v-if="data.tfa_x_paye == 1" class="bi bi-check2 text-success me-3"></i>
                    <span>{{data.tfa_x_paye == 1 ? 'Payées' : 'En attente'}}</span>
                  </div>
                </template>
                <template v-slot:cell-action="{row: data }">
                  <div class="d-flex justify-content-center cursor-pointer" @click="changeTitle(data.pro_seq)">
                    <el-tooltip content='Afficher le projet' placement="top" effect="light" :show-after="300">
                    <span class="svg-icon svg-icon-1 me-3 btn btn-sm btn-light-primary fs-6">
                      <i class="bi bi-search" />
                    </span>
                    </el-tooltip>
                  </div>
                </template>
              </Datatable>
              <el-empty v-if="!state.myList || state.myList.length == 0" description="Aucune commission"/>
              <transition name="fade" class="d-md-none">
                <div v-if="!state.loading">

                    <div class="card-body py-3  text-start p-0">
                      <div class="tab-content">
                        <div class="tab-pane fade active show" id="kt_table_widget_5_tab_1">
                          <div class="table-responsive">
                            <table
                              class="
                                table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4"
                            >
                              <thead>
                                <tr class="border-0">
                                  <th class="p-0"></th>
                                  <th class="p-0"></th>
                                  <th class="p-0"></th>
                                  <th class="p-0"></th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="item, index in state.myList" :key="index" class="h-70px">
                                  <td class="fw-bold text-left fs-7 d-table-cell d-md-none p-2 m-10" width="100%">
                                    <div class="p-2">
                                      <div class="badge fs-8" :class="payClasses(item.tfa_x_paye)" @click="payTheCom(item.tfa_x_paye, item.tfa_seq)">
                                        {{item.tfa_x_paye == 1 ? 'Payées' : 'En attente'}}
                                      </div>
                                      <div class="mt-2 ms-2 mt-3">
                                        <span class="fw-bolder text-dark text-left fs-7">{{ formatDate(item.tfa_date_creation) }}</span>
                                      </div>
                                      <div class="ms-2">
                                        <span class="text-muted fw-bold fs-7">{{ item.soc_nom_societe }}</span>
                                      </div>
                                      <div class="d-flex align-items-center text-dark fw-bolder fs-7 ms-2">
                                        <span class="text-dark fw-bolder mb-1 fs-6">{{ item.pro_code }}</span>
                                      </div>
                                    </div>
                                  </td>
                                  <td class="fw-bold text-left fs-7 d-table-cell d-md-none p-2 m-10">
                                    <div class="pe-6 w-150px text-end">
                                      <div class="text-muted fw-old d-block fs-7">{{ getFormattedTotal(item.total_marge) }}</div>
                                      <div class="text-end fs-8 btn fw-bolder cursor-text p-0">Coms: {{ item.tfa_pourc_com }}{{item.tfa_pourc_com ? '%' : '-'}}</div>
                                      <div class="fw-bolder fs-6 text-primary">{{ getFormattedTotal(item.total_com) }}</div>
                                    </div>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
              </transition>
          </div>
      </el-main>

      <el-aside width="300px" class="card-aside d-none d-md-block ms-md-6">
        <el-tabs class="tabsPresta mb-3">
          <el-tab-pane disabled :label="'Sous-totaux'" ></el-tab-pane>
        </el-tabs>

        <div class="row mb-3">
          <label class="col-lg-12 col-form-label fw-bolder fs-7">Marge sur la période :</label>
          <div class="col-lg-12 col-12 fv-row ps-15">
            <input disabled type="text" class="form-control ps-4 text-end w-80" :value="getFormattedTotal(state.sousTotaux.periode)" />
          </div>
        </div>  

        <div class="row mb-3">
          <label class="col-lg-12 col-form-label fw-bolder fs-7">Commissions payées :</label>
          <div class="col-lg-12 col-12 fv-row ps-15">
            <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.payes)" />
          </div>
        </div>  

        <div class="row mb-3">
          <label class="col-lg-12 col-form-label fw-bolder fs-7">Commissions en attente :</label>
          <div class="col-lg-12 col-12 fv-row ps-15">
            <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.attente)" />
          </div>
        </div>  

        <div class="row mb-3">
          <label class="col-lg-12 col-form-label fw-bolder fs-7">Soit un total :</label>
          <div class="col-lg-12 col-12 fv-row ps-15">
            <input disabled type="text" class="form-control ps-4 text-end" :value="getFormattedTotal(state.sousTotaux.total)" />
          </div>
        </div>  
      </el-aside>

    </el-container>


    </div>
  </transition>
</template>

<script lang="ts">
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { defineComponent, onMounted, reactive, ref} from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable.vue";
import * as moment from "moment"
import mAxiosApi from "@/api";
import { ElMessage, ElMessageBox } from "element-plus";
import SearchAndFilter from "@/views/still/search_and_filters.vue";

export default defineComponent({
  name: "Commissions",
  components: {
    Datatable,
    SearchAndFilter,
  },

  setup() {
    setCurrentPageBreadcrumbs("Commissions", ["Pages", "Profile"]);

    const state = reactive({
      loading: true,
      byMois: '',
      sousTotaux: {
        payes: 0,
        attente: 0,
        periode: 0,
        total: 0,
      },
      loadingDatatable: false,
      updateList: 0,
      myList: [] as any,
      allRest: [] as any,
      initialList: [] as any,
      displayTotaux: false,
    });

    const tableHeader = ref([
      {
        name: "Facture<br>Client",
        key: "tfa_date_creation",
        sortable: true,
      },
      {
        name: "Projet<br/>Facture",
        key: "pro_code",
        sortable: false,
      },
      {
        name: "% com",
        key: "tfa_pourc_com",
        sortable: true,
      },
      {
        name: "Marge<br/>Commissions",
        key: "total_com",
        sortable: true,
      },
      {
        name: "Paiement",
        key: "tfa_x_paye",
        sortable: true,
      },
      {
        name: "",
        key: "action",
        sortable: false,
      },
    ]);

    const tableFilters = ref([] as any)
    // Gerer mettre dans default le mois de la dernière ligne 

    const formatDate = (d) => {
      return moment.default(d).format("DD/MM/YYYY");
    };

    const refreshDatas = async (seq, allSeqs = []) => {
      if (seq == -1) {
        const response = await mAxiosApi.prototype.getAxios("/getCommissions");
        allSeqs.forEach(seq => {
          const initialIndex = state.initialList.findIndex(el => el.tfa_seq == seq);
          const myListIndex = state.myList.findIndex(el => el.tfa_seq == seq);
          state.initialList[initialIndex] = response.results[response.results.findIndex(el => el.tfa_seq == seq)];
          state.myList[myListIndex] = response.results[response.results.findIndex(el => el.tfa_seq == seq)];
        });
      }else{
        const response = await mAxiosApi.prototype.getAxios("/getCommissions/" + seq);
        const initialIndex = state.initialList.findIndex(el => (el as any).tfa_seq == seq);
        const myListIndex = state.myList.findIndex(el => (el as any).tfa_seq == seq);
        state.initialList[initialIndex] = response.results[0];
        state.myList[myListIndex] = response.results[0];
      }
      calcTotaux();
      state.updateList = state.updateList + 1;
    }

    const payAll = async() => {
      let moreThanZero = false;
      const allSeqs = [] as any;
      state.myList.forEach(async (el) =>{
        if (el.tfa_x_paye != 1 && state.allRest.length == 0) {
          moreThanZero = true;
          allSeqs.push(el.tfa_seq)
        }
      });
      if (moreThanZero) {
        const datas = {ids: allSeqs}
        console.log(datas)
        await mAxiosApi.post("/payCommission", datas)
        refreshDatas(-1, allSeqs);
      }

      const plural = allSeqs.length != 1 ? 's ' : ' ';
      ElMessage({
        type: 'success',
        message: allSeqs.length + " commission" + plural + "passée" + plural + "en 'Payées'",
      })
    }

    const payTheCom = (tfa_x_paye, seq) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        ElMessageBox.confirm(
          "Voulez vous passer cette commission en 'Payées' ?",
          {
            confirmButtonText: 'OK',
            cancelButtonText: 'Annuler',
          }
        )
        .then(async() => {
          const datas = {ids: [seq]}
          await mAxiosApi.post("/payCommission", datas);
          refreshDatas(seq);
          ElMessage({
            type: 'success',
            message: "Commission passée en 'Payées'",
          })
        })
      }
    }

    const editCom = (tfa_x_paye, seq) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        ElMessageBox.prompt('Entrez le nouveau pourcentage', 'Modification du pourcentage', {
          confirmButtonText: 'OK',
          cancelButtonText: 'Annuler',
          inputPattern:
            /^(\d{1,2}(\.\d{1,2})?|100(\.0{1,2})?)$/,
          inputErrorMessage: 'Pourcentage incorrect',
        })
        .then(async({ value }) => {
          await mAxiosApi.post("/editPercCommission/" + seq + "/" + value);
          refreshDatas(seq);
          ElMessage({
            type: 'success',
            message: `Nouveau pourcentage: ${value}%`,
          })
        })
      }
    }

    const changeTitle = (data) => {
      const initialTitle = document.title;
      document.title = 'display_project=' + data;
      setTimeout(() => {
        document.title = initialTitle
      }, 3000);
    }

    const calcTotaux = () => {
      state.sousTotaux = {
        payes: 0,
        attente: 0,
        periode: 0,
        total: 0,
      }
      state.myList.forEach(element => {
        if (element.tfa_x_paye == 1) {
          state.sousTotaux.payes += parseFloat(element.total_com); 
        }else{
          state.sousTotaux.attente += parseFloat(element.total_com);
        }
        state.sousTotaux.periode += parseFloat(element.total_marge);
        state.sousTotaux.total += parseFloat(element.total_com); 
      });
    }

    const comClasses = ((tfa_x_paye) => {
      if (tfa_x_paye != 1 && state.allRest.length == 0) {
        return 'btn-light-primary btn-sm cursor-pointerIMP'
      }
    })

    const payClasses = (tfa_x_paye) => {
      if (tfa_x_paye == 1) {
        return 'badge-light-success'
      }else{
        return state.allRest.length == 0 ? 'badge-light-warning cursor-pointer' : 'badge-light-warning'
      }
    }

    const getFormattedTotal = (value) => {
      const n = (Math.ceil(value*100)/100).toFixed(2);
      return commify(n) + ' €';
    }

    function commify(n) {
      var parts = n.toString().split(".");
      const numberPart = parts[0];
      const decimalPart = parts[1];
      const thousands = /\B(?=(\d{3})+(?!\d))/g;
      return numberPart.replace(thousands, "'") + (decimalPart ? "." + decimalPart : "");
    }
    
    onMounted(async () => {
      const response = await mAxiosApi.prototype.getAxios("/getCommissions");
      state.initialList = response.results;
      state.myList = state.initialList;
      if (state.myList.length>0) {
        tableFilters.value.push({ name: "Mois", key: "keymonth", label: "displaymonth", default: [state.initialList[0].keymonth] })
      }
      // state.defaultFilter = "10 / 2023"
      // console.log(tableFilters.value.key)
      calcTotaux();
      
      const allRest = await mAxiosApi.prototype.getAxios("/getPermissions");
      state.allRest =  allRest.results.filter(
          (item: any) =>item.per_code_permission == 'EDIT_COM'
      );
      state.loading = false
    });

    return{
      state,
      tableHeader,
      tableFilters,
      formatDate,
      changeTitle,
      payTheCom,
      editCom,
      payAll,
      calcTotaux,
      comClasses,
      payClasses,
      getFormattedTotal
    }
  }
});
</script>
